import { produce } from 'immer';
import api from 'api';
import templateStates from 'shared/constants/contractTemplateStates';
import { reportingFormStates } from 'shared/ReportingForms/constants/reportingFormStates';
import { sortBy } from 'shared/utils/sortUtils';

/**
 * Lomakkeet.
 */
export const reportingForms = {
    state: {
        templates: [],
        reportingForms: [],
        openReportingForms: [],
        reportingForm: {},
        reportingFormGroup: null,
        searchReportingForms: [],
    },

    reducers: {
        setReportingFormTemplates: (state, templates) => ({ ...state, templates }),
        setReportingForms: (state, reportingForms) => ({ ...state, reportingForms }),
        setSearchReportingForms: (state, searchReportingForms) => ({ ...state, searchReportingForms }),
        setReportingForm: (state, reportingForm) => produce(state, (draftState) => {
            draftState.reportingForm = Object.assign(reportingForm, { data: reportingForm?.decryptedData });
            const index = draftState.reportingForms.findIndex((rf) => rf.id === reportingForm.id);

            // päivitetään/lisätään myös listale niin ei tarvi päivitellä erikseen koko listaa
            if (index === -1) {
                draftState.reportingForms.push(reportingForm);
            } else {
                draftState.reportingForms[index] = reportingForm;
            }
        }),
        clearReportingForm: (state) => ({ ...state, reportingForm: {} }),
        setReportingFormGroup: (state, reportingFormGroup) => ({ ...state, reportingFormGroup }),
    },

    selectors: {
        getReportingFormTemplates: (state) => state?.templates ?? [],
        getReportingForms: (state) => state.reportingForms ?? [],
        getSearchReportingForms: (state) => state.searchReportingForms ?? [],
        getReportingForm: (state) => state.reportingForm ?? {},
        getReportingFormGroup: (state) => state.reportingFormGroup ?? null,

        isReportingFormDraft: (state) => state.reportingForm?.state === reportingFormStates.DRAFT,
        isReportingFormOpen: (state) => state.reportingForm?.state === reportingFormStates.OPEN,
        isReportingFormConfirmed: (state) => state.reportingForm?.state === reportingFormStates.CONFIRMED,
        isReportingFormInvalidated: (state) => state.reportingForm?.state === reportingFormStates.INVALIDATED,
    },

    effects: (dispatch) => ({
        async fetchReportingForms({ resourceType, resourceId }) {
            try {
                const response = await api.get(`/api/v3/reporting-forms?resourceType=${resourceType}&resourceId=${resourceId}`);
                if (response.status === 'ok') {
                    this.setReportingForms(response?.data);
                } else {
                    dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
                }
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async fetchSearchReportingForms({ state }) {
            try {
                const response = await api.get(`/api/v3/reporting-forms/search?state=${state}`);
                if (response.status === 'ok') {
                    this.setSearchReportingForms(response?.data);
                } else {
                    dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
                }
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async fetchReportingFormTemplates({ subType, resourceId, resourceType } = {}) {
            try {
                const response = await api.get(`/api/v3/reporting-form/templates?state=${templateStates.STATE_ACTIVE}&subType=${subType}&resourceId=${resourceId}&resourceType=${resourceType}`);
                this.setReportingFormTemplates(sortBy(response.data ?? [], 'name'));
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakepohjia ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async fetchReportingForm(reportingFormId) {
            try {
                const response = await api.get(`/api/v3/reporting-form/${reportingFormId}`);
                if (response?.status === 'ok') {
                    this.setReportingForm(response?.data);
                }
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async postReportingForm(values) {
            try {
                return await api.post(`/api/v3/reporting-form`, values);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu tallentaa.', {}, 'reporting_forms'));
            }
        },

        async putReportingForm({ reportingFormId, values }) {
            try {
                return await api.put(`/api/v3/reporting-form/${reportingFormId}`, values);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu päivittää.', {}, 'reporting_forms'));
            }
        },

        async confirmReportingForm(reportingFormId) {
            try {
                return await api.post(`/api/v3/reporting-form/${reportingFormId}/confirm`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu vahvistaa.', {}, 'reporting_forms'));
            }
        },

        async unconfirmReportingForm(reportingFormId) {
            try {
                return await api.post(`/api/v3/reporting-form/${reportingFormId}/unconfirm`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakeen vahvistusta ei voitu perua.', {}, 'reporting_forms'));
            }
        },

        async deleteReportingForm(reportingFormId) {
            try {
                return await api.del(`/api/v3/reporting-form/${reportingFormId}`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu poistaa.', {}, 'reporting_forms'));
            }
        },
    })
};
