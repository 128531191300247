import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Placeholder, UserBlock } from 'shared/components';
import { templateSubTypes } from 'ContractV3/Builder/constants/templateTypes';
import { FormField } from 'shared/components/Formik';

// Tämä import paskoo koko pohjaeditorin jossa tämä palikka käytössä
//import { userSelectors } from 'shared/UserDetails/stores/user';

const ReportingFormsEmployee = ({ templateSubType, attributes }) => {
    const { isAccountNumberVisible, isEmailHidden, isSsnVisible, isPhoneNumberHidden, isAddressHidden } = attributes;
    const isBuildMode = useSelector((state) => select.contract
        ? select.contract.isBuildMode(state)
        : false);

    const resolvedEmployees = useSelector((state) => {
        if (isBuildMode) return [];

        if (templateSubType === templateSubTypes.FAMILY_CARER) {
            return state.user?.toJS();//userSelectors.getUser(state).toJS();
        }
        return select.contract.getEmployees(state);
    });
    const employees = Array.isArray(resolvedEmployees) ? resolvedEmployees : [resolvedEmployees];

    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isLoading = useSelector((state) => state.loading?.effects?.contract
        ? state.loading.effects.contract.fetchContract
        : false);

    if (isBuildMode) {
        return <b className="o-form__text">Tiedot eivät näkyvissä editorissa.</b>;
    }

    return (
        <Placeholder isPending={isLoading}>
            {/*TODO: User/constants/routes.js refaktorointi => exporttaa vain routet ulos, ei komponentteja*/}
            {employees.map((employee, key) => {
                const details = [
                    [isSsnVisible ? employee.socialSecurityNumber : ''],
                    [! isEmailHidden ? employee.email : ''],
                    [! isAddressHidden ? employee.fullAddress : ''],
                    [! isPhoneNumberHidden ? employee.phoneNumber : ''],
                ].filter((detail) => detail.toString().trim() !== '');

                //const isCommuneTimeRegistrationEnabled = employee?.isCommuneTimeRegistrationEnabled;
                return (
                    <FormField key={key}
                        label={isCompany ? _trans('Hoitajan tiedot', {}, 'jobContract') : _trans('Työntekijän tiedot', {}, 'jobContract')}
                    >
                        <div className="u-margin-bottom-small">
                            <div className="u-margin-bottom-tiny">
                                <UserBlock
                                    user={employee}
                                    size="small"
                                    relationType="employee"
                                    isSsnVisible={isSsnVisible}
                                    hasLinkToDetails={isCompany || isCommune}
                                />
                            </div>
                            <div className="o-form__text">
                                {details.map((detail, key) => (
                                    <Fragment key={key}>
                                        {detail}<br/>
                                    </Fragment>
                                ))}
                                {isAccountNumberVisible && (
                                    <Fragment>
                                        <span title={_trans('Tilinumero', {}, 'common')}>
                                            {employee.accountNumber}
                                        </span>
                                        <br/>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </FormField>
                );
            })}
        </Placeholder>
    );
};

ReportingFormsEmployee.propTypes = {
    /**
     * Pohjan alatyyppi. Tämän perusteella selvitetään hoitajan/työntekijän tiedot.
     */
    templateSubType: PropTypes.oneOf([templateSubTypes.CONTRACT, templateSubTypes.FAMILY_CARER]).isRequired,
    attributes: PropTypes.shape({
        isAccountNumberVisible: PropTypes.bool.isRequired,
        isEmailHidden: PropTypes.bool.isRequired,
        isPhoneNumberHidden: PropTypes.bool.isRequired,
        isAddressHidden: PropTypes.bool.isRequired,
        isSsnVisible: PropTypes.bool.isRequired,
    }).isRequired,
};

export default ReportingFormsEmployee;
